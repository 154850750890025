import { makeModule } from '@codium/codium-vue'

export const storeModule = makeModule('published', {
  parent: 'courses',
  singular: 'course',
  plural: 'courses',
  uri: () => {
    return () => 'courses/published'
  }
})
