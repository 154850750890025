import { makeResource, mapped } from '@codium/codium-vue'
import { make } from 'vuex-pathify'
import { http } from '@/utils'
import { storeModule as published } from './courses/published'
import { storeModule as accreditationValues } from './courses/accreditation-values'
import { storeModule as revisions } from './courses/revisions'
import { storeModule as topics } from './courses/topics'

export const { storeModule, stateMapper, helperComputed } = makeResource('courses', {
  storeModule: {
    modules: {
      published,
      accreditationValues,
      revisions,
      topics
    },

    state: {
      courseRejectionPromise: null,
    },

    getters: {
      onlineCourse: (state) => state.course.type === 'online',
      onDemandCourse: (state) => state.course.type === 'on_demand',
      inPersonCourse: (state) => state.course.type === 'in_person',
      courseSettings: (state) => ({
        provider_id: state.course.provider.id,
        review: state.course.review,
        featured: state.course.featured,
        rejection_reason: state.course.rejection_reason,
      })
    },

    actions: {
      async getCourse ({ getters, dispatch }, { id, request }) {
        const response = await http.get(getters.uri(id), request)

        dispatch('setCourse', response.data.data)
      },

      async getCourseByUuid ({ getters, dispatch }, uuid) {
        const response = await http.get(`course-uuid/${uuid}`)
        dispatch('setCourse', response.data.data)
      },

      openCourseRejectionModal ({ commit }) {
        return new Promise((resolve, reject) => {
          commit('SET_COURSE_REJECTION_PROMISE', { resolve, reject })
        })
      },

      async archiveCourse ({ getters, dispatch }, id) {
        const response = await http.post(`${getters.uri(id)}/archive`)

        dispatch('setCourse', response.data.data)
      },

      async restoreCourse ({ getters, dispatch }, id) {
        const response = await http.post(`${getters.uri(id)}/restore`)

        dispatch('setCourse', response.data.data)
      },

      async updateCourseSettings ({ getters, dispatch }, { id, payload }) {
        const response = await http.put(`${getters.uri(id)}/settings`, payload)

        dispatch('setCourse', response.data.data)
      }
    },

    mutations: {
      ...make.mutations('courseRejectionPromise'),
    }
  },

  resource () {
    return {
      id: mapped(null),
      name: mapped(null),
      type: mapped(null),
      booking_url: mapped(null),
      short_description: mapped(null),
      description: mapped(null),
      start_date: mapped(null),
      start_time: mapped(null),
      timezone: mapped(null),
      duration: {
        unit: mapped(null),
        value: mapped(null),
      },
      price: mapped(null),
      currency: mapped(null),
      featured: mapped(false),
      rejection_reason: mapped(null),
      topics: mapped([]),
      speakers: mapped([]),
      url: mapped(null),
      address: {
        formatted_address: mapped(null),
        street: mapped(null),
        suburb: mapped(null),
        state: mapped(null),
        state_short: mapped(null),
        postcode: mapped(null),
        country: mapped(null),
        latitude: mapped(null),
        longitude: mapped(null)
      },
      locations: mapped([]),
      ahpra_verification_required: mapped(null),
      do_not_show_to_students: mapped(null),
      professions: mapped([]),
      restriction_professions: mapped([]),
      primary_fields: mapped([]),
      restriction_primary_fields: mapped([]),
      qualifications: mapped([])
    }
  }
})
