import { applyRouteMeta } from '@codium/codium-vue'

export default applyRouteMeta({
  template: 'app',
  requiresAuth: true
}, [
  {
    path: '/my',
    component: () => import(/* webpackChunkName: "users" */ '@/views/users/My'),
    children: [
      {
        path: 'account',
        name: 'my.account',
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/MyAccount')
      },
      {
        path: 'security',
        name: 'my.security',
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/MySecurity')
      },
      {
        path: 'learner-profile',
        name: 'my.learner-profile',
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/MyLearnerProfile')
      },
      {
        path: 'subscription',
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/MySubscription'),
        children: [
          {
            path: '',
            name: 'my.subscription',
            component: () => import(/* webpackChunkName: "users" */ '@/views/users/MySubscriptionSingle'),
            meta: {
              isA: 'user',
            },
          },
          {
            path: 'subscribe',
            name: 'my.subscription.subscribe',
            component: () => import(/* webpackChunkName: "users" */ '@/views/users/MySubscriptionSubscribe'),
            meta: {
              isA: 'user',
            },
          },
          {
            path: 'change-plan',
            name: 'my.subscription.change-plan',
            component: () => import(/* webpackChunkName: "users" */ '@/views/users/MySubscriptionSubscribe'),
            meta: {
              isA: 'user',
            },
          },
          {
            path: 'update-payment-method',
            name: 'my.subscription.update-payment-method',
            component: () => import(/* webpackChunkName: "users" */ '@/views/users/MySubscriptionUpdatePaymentMethod'),
            meta: {
              isA: 'user',
            },
          },
          {
            path: 'invoice/:invoiceId',
            name: 'my.subscription.invoice-single',
            component: () => import(/* webpackChunkName: "users" */ '@/views/users/MySubscriptionInvoiceSingle'),
            meta: {
              isA: 'user',
            },
          },
        ],
      }
    ]
  },
  {
    path: '/my/plans',
    component: () => import(/* webpackChunkName: "users" */ '@/views/users/Plans'),
    meta: {
      requiresSubscription: true,
    },
    children: [
      {
        path: 'professional-development-plans',
        name: 'my.plans.professional-development-plans',
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/MyProfessionalDevelopmentPlans'),
        meta: {
          requiresSubscription: true,
        },
      },
      {
        path: 'learning-plans',
        name: 'my.plans.learning-plans',
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/MyLearningPlans'),
        meta: {
          requiresSubscription: true,
        },
      },
    ]
  },
  {
    path: '/my/learning-plans/add',
    name: 'my.learning-plans.add',
    component: () => import(/* webpackChunkName: "users" */ '@/views/learning-plans/user/LearningPlansAdd.vue'),
    meta: {
      requiresSubscription: true,
    },
  },
  {
    path: '/my/learning-plans/:learningPlanId',
    name: 'my.learning-plans.single',
    props: true,
    component: () => import(/* webpackChunkName: "users" */ '@/views/users/MyLearningPlansSingle'),
    meta: {
      requiresSubscription: true,
    },
  },
  {
    path: '/my/professional-development-plans/add',
    name: 'my.professional-development-plans.add',
    props: false,
    component: () => import(/* webpackChunkName: "users" */ '@/views/users/MyProfessionalDevelopmentPlansCreate'),
    meta: {
      requiresSubscription: true,
    },
    children: [
      {
        path: 'add-overview',
        name: 'my.professional-development-plans.add.overview',
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/DevelopmentPlanOverviewCreate'),
        meta: {
          requiresSubscription: true,
        },
      },
    ]
  },
  {
    path: '/my/professional-development-plans/:professionalDevelopmentPlanId',
    props: true,
    component: () => import(/* webpackChunkName: "users" */ '@/views/users/MyProfessionalDevelopmentPlansSingle'),
    meta: {
      requiresSubscription: true,
    },
    children: [
      {
        path: 'overview',
        name: 'my.professional-development-plans.single.overview',
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/DevelopmentPlanOverview'),
        meta: {
          requiresSubscription: true,
        },
      },
      {
        path: 'self-evaluation',
        name: 'my.professional-development-plans.single.self-evaluation',
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/DevelopmentPlanSelfEvaluation'),
        meta: {
          requiresSubscription: true,
        },
      },
      {
        path: 'identified-goals',
        name: 'my.professional-development-plans.single.identified-goals',
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/DevelopmentPlanIdentifiedGoals'),
        meta: {
          requiresSubscription: true,
        },
      },
      {
        path: 'annual-reflection',
        name: 'my.professional-development-plans.single.annual-reflection',
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/DevelopmentPlanAnnualReflection'),
        meta: {
          requiresSubscription: true,
        },
      },
      {
        path: 'annual-cpd-summary',
        name: 'my.professional-development-plans.single.annual-cpd-summary',
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/DevelopmentPlanAnnualCpdSummary'),
        meta: {
          requiresSubscription: true,
        },
      },
    ]
  }
])
