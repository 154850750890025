import { makeResource, mapped } from '@codium/codium-vue'
import { http } from '@/utils'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('qualifications', {
  storeModule: {
    actions: {
      async toggleStatus ({ dispatch, state, getters }, { id }) {
        const response = await http.put(`${getters.uri(`${id}`)}/toggle-status`)

        dispatch('setQualification', response.data.data)
        dispatch('removeQualification', state.qualification)
      },
    }
  },
  resource () {
    return {
      id: null,
      profession_id: mapped(null),
      name: mapped(null),
      abbreviation: mapped(null),
      status: mapped(null),
      archived: null
    }
  }
})
