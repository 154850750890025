import { makeResource, mapped } from '@codium/codium-vue'
import { http, pagination } from '@/utils'

export const { storeModule, stateMapper } = makeResource('invoices', {
  storeModule: {
    actions: {
      async getProviderInvoices ({ dispatch, state }, { params, providerId, fresh = false }) {
        let page

        if (fresh) {
          page = 1
        } else if (state.meta) {
          if (pagination(state.meta)) {
            throw new Error('No more results.')
          }

          page = state.meta.current_page + 1
        }

        const response = await http.get(`/providers/${providerId}/invoices`, { params })

        if (page > 1) {
          dispatch('addInvoices', response.data.data)
        } else {
          dispatch('setInvoices', response.data.data)
        }

        dispatch('setMeta', response.data.meta || null)

        return response.data.data
      },

      async getUserInvoices ({ dispatch, state }, { params, userId, fresh = false }) {
        let page

        if (fresh) {
          page = 1
        } else if (state.meta) {
          if (pagination(state.meta)) {
            throw new Error('No more results.')
          }

          page = state.meta.current_page + 1
        }

        const response = await http.get(`/users/${userId}/invoices`, { params })

        if (page > 1) {
          dispatch('addInvoices', response.data.data)
        } else {
          dispatch('setInvoices', response.data.data)
        }

        dispatch('setMeta', response.data.meta || null)

        return response.data.data
      }
    },
  },

  resource () {
    return {
      id: mapped(null),
      stripe_id: mapped(null),
      stripe_invoice_number: mapped(null),
      amount: mapped(0),
      amount_paid: mapped(0),
      status: mapped(null),
      due_date: mapped(null),
    }
  }
})
