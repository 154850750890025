import { applyRouteMeta } from '@codium/codium-vue'
import toNumber from 'lodash/toNumber'

export default applyRouteMeta({
  template: 'app',
  requiresAuth: true
}, [
  {
    path: '/users',
    name: 'users',
    meta: {
      can: 'read users'
    },
    component: () => import(/* webpackChunkName: "users" */ '@/views/users/UsersList.js')
  },
  {
    path: '/users/add',
    name: 'users.add',
    meta: {
      can: 'write users'
    },
    component: () => import(/* webpackChunkName: "users" */ '@/views/users/UsersAdd')
  },
  {
    path: '/users/add-provider',
    name: 'users.add-provider',
    meta: {
      can: 'write users'
    },
    component: () => import(/* webpackChunkName: "users" */ '@/views/users/provider/UsersAdd')
  },
  {
    path: '/users/:userId',
    component: () => import(/* webpackChunkName: "users" */ '@/views/users/UsersSingle'),
    props: ({ params }) => ({ userId: toNumber(params.userId) }),
    children: [
      {
        path: '/users/:userId',
        name: 'users.single.details',
        meta: {
          can: 'read users'
        },
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/UsersSingleDetails')
      },
      {
        path: '/users/:userId/security',
        name: 'users.single.security',
        meta: {
          can: 'read users'
        },
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/UsersSingleSecurity')
      },
      {
        path: '/users/:userId/learning-plans',
        name: 'users.single.learning-plans',
        meta: {
          can: 'read users',
          requiresSubscription: true,
        },
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/UsersSingleLearningPlans')
      },
      {
        path: '/users/:userId/profile',
        name: 'users.single.profile',
        meta: {
          can: 'read users'
        },
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/UsersSingleProfile')
      },
      {
        path: '/users/:userId/permissions',
        name: 'users.single.permissions',
        meta: {
          can: 'write permissions'
        },
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/UsersSinglePermissions')
      },
      {
        path: '/users/:userId/subscription',
        name: 'users.single.subscription',
        meta: {
          isA: 'admin'
        },
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/UsersSingleSubscription')
      },
      {
        path: '/users/:userId/subscription/subscribe',
        name: 'users.single.subscription.subscribe',
        meta: {
          isA: 'admin'
        },
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/UsersSingleSubscribe')
      },
      {
        path: '/users/:userId/subscription/invoice/:invoiceId',
        name: 'users.single.subscription.invoice',
        meta: {
          isA: 'admin'
        },
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/UsersSingleSubscriptionInvoiceSingle')
      }
    ]
  }
])
