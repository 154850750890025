import { makeModule } from '@codium/codium-vue'

export const storeModule = makeModule('topics', {
  parent: 'lists',
  getters: {
    flattened: (state) => state.topics.reduce((topics, topic) => [
      ...topics,
      {
        id: topic.id,
        name: topic.name,
        parent_id: null,
        profession_id: topic.profession_id,
      },
      ...topic.children ?? []
    ], [])
  }
})
