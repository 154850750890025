import { applyRouteMeta } from '@codium/codium-vue'
import { toNumber } from 'lodash'

export default applyRouteMeta({
  template: 'app',
  requiresAuth: true
}, [
  {
    path: '/settings',
    name: 'settings',
    meta: {
      can: 'read settings'
    },
    component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/SettingsList')
  },
  {
    path: '/settings/primary-fields',
    name: 'settings.primary-fields',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/primary-fields/PrimaryFields')
  },
  {
    path: '/settings/universities',
    name: 'settings.universities',
    meta: {
      can: 'read settings'
    },
    component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/universities/UniversityList')
  },
  {
    path: '/settings/topics',
    name: 'settings.topics',
    meta: {
      can: 'read settings'
    },
    component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/topics/TopicList')
  },
  {
    path: '/settings/accreditation-bodies',
    name: 'settings.accreditation-bodies',
    meta: {
      can: 'read settings'
    },
    component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/accreditation-bodies/AccreditationBodies')
  },
  {
    path: '/settings/accreditation-bodies/:accreditationBodyId',
    name: 'settings.accreditation-bodies.single',
    props: ({ params }) => ({ accreditationBodyId: toNumber(params.accreditationBodyId) }),
    component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/accreditation-bodies/AccreditationBodiesSingle')
  },
  {
    path: '/settings/cpd-bodies',
    name: 'settings.cpd-bodies',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/cpd-bodies/CpdBodies')
  },
  {
    path: '/settings/qualifications',
    name: 'settings.qualifications',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/qualifications/QualificationList')
  },
])
