import { makeResource, mapped } from '@codium/codium-vue'
import { http } from '@/utils'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('accreditationBodies', {
  storeModule: {
    actions: {
      async updateAccreditationBody ({ dispatch, getters }, { id, payload }) {
        const response = await http.post(getters.uri(id), payload)

        dispatch('setAccreditationBody', response.data.data)
      },

      async deleteAccreditationBodyLogo ({ getters, commit }, id) {
        await http.delete(`${getters.uri(id)}/logo`)

        commit('SET_ACCREDITATION_BODY_LOGO', null)
      }
    },

    mutations: {
      SET_ACCREDITATION_BODY_LOGO (state, value) {
        state.accreditationBody.logo = value
      }
    }
  },

  resource () {
    return {
      id: mapped(null),
      name: mapped(null),
      body_acronym: mapped(null),
      status: mapped(null),
      image_path: mapped(null),
      profession_id: mapped(null),
    }
  }
})
