import { makeModule, mapped } from '@codium/codium-vue'
import { http } from '@/utils'

export const storeModule = makeModule('accreditationBodies', {
  state: {
    accreditationBodies: []
  },

  parent: 'users',

  actions: {
    async getAccreditationBodies ({ commit }, { id }) {
      const response = await http.get(`users/${id}/accreditation-bodies`)
      commit('SET_ACCREDITATION_BODIES', { accreditationBodies: response.data.data })
    },
  },

  mutations: {
    SET_ACCREDITATION_BODIES (state, { accreditationBodies }) {
      state.accreditationBodies = accreditationBodies
    }
  },

  resource () {
    return [{
      id: mapped(null),
      name: mapped(null),
      abbreviation: mapped(null)
    }]
  }
})
