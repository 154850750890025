import { applyRouteMeta } from '@codium/codium-vue'
import { isNumber, toNumber } from 'lodash'

export default applyRouteMeta({
  template: 'app',
  requiresAuth: true,
  can: 'read courses'
}, [
  {
    path: '/courses',
    name: 'courses',
    component: () => import(/* webpackChunkName: "courses" */ '@/views/courses/CoursesList')
  },
  {
    path: '/topics',
    name: 'topics',
    component: () => import(/* webpackChunkName: "courses" */ '@/views/courses/CoursesByTopic')
  },
  {
    path: '/courses/:courseId',
    props: ({ params }) => ({ courseId: isNumber(params.courseId) ? toNumber(params.courseId) : params.courseId }),
    component: () => import(/* webpackChunkName: "courses" */ '@/views/courses/CoursesShow'),
    children: [
      {
        path: '/courses/:courseId',
        name: 'courses.show',
        component: () => import(/* webpackChunkName: "courses" */ '@/views/courses/CoursesShowDetails')
      },
      {
        path: '/courses/:courseId/accreditation-values',
        name: 'courses.show.accreditation-values',
        component: () => import(/* webpackChunkName: "courses" */ '@/views/courses/CoursesShowAccreditationValues')
      },
      {
        path: '/courses/:courseId/revision-history',
        name: 'courses.show.revisions',
        component: () => import(/* webpackChunkName: "courses" */ '@/views/courses/CoursesShowRevisionHistory')
      },
      {
        path: '/courses/:courseId/learner-quality-score',
        name: 'courses.show.learner-quality-score',
        component: () => import(/* webpackChunkName: "courses" */ '@/views/courses/CoursesShowLearnerQualityScore')
      }
    ]
  }
])
