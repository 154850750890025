import { applyRouteMeta } from '@codium/codium-vue'
import { toNumber } from 'lodash'

export default applyRouteMeta({
  template: 'app',
  requiresAuth: true,
  can: 'read providers'
}, [
  {
    path: '/providers',
    name: 'providers',
    component: () => import(/* webpackChunkName: "providers" */ '@/views/providers/ProvidersList.js')
  },
  {
    path: '/providers/add',
    name: 'providers.add',
    component: () => import(/* webpackChunkName: "providers" */ '@/views/providers/ProvidersAdd')
  },
  {
    path: '/organisation',
    component: () => import(/* webpackChunkName: "providers" */ '@/views/providers/provider/ProvidersSingle.vue'),
    children: [
      {
        path: 'details',
        name: 'providers.single',
        component: () => import(/* webpackChunkName: "providers" */ '@/views/providers/ProvidersSingleDetails.vue'),
      },
      {
        path: 'subscription',
        name: 'providers.single.subscription',
        component: () => import(/* webpackChunkName: "providers" */ '@/views/providers/provider/ProvidersSingleSubscription.vue'),
        meta: {
          isA: 'provider',
        },
      },
      {
        path: 'learner-quality-score',
        name: 'providers.single.learner-quality-score',
        component: () => import(/* webpackChunkName: "providers" */ '@/views/providers/provider/ProvidersSingleLearnerQualityScore'),
        meta: {
          isA: 'provider',
        },
      },
      {
        path: 'subscription/billing-email',
        name: 'providers.single.subscription.billing-email',
        component: () => import(/* webpackChunkName: "providers" */ '@/views/providers/provider/ProvidersSingleSubscriptionBillingEmailCard.vue'),
        meta: {
          isA: 'provider',
        },
      },
      {
        path: 'subscription/change-plan',
        name: 'providers.single.subscription.change-plan',
        component: () => import(/* webpackChunkName: "providers" */ '@/views/providers/provider/ProvidersSingleSubscriptionChangePlanCard.vue'),
        meta: {
          isA: 'provider',
        },
      },
      {
        path: 'subscription/payment-method',
        name: 'providers.single.subscription.payment-method',
        component: () => import(/* webpackChunkName: "providers" */ '@/views/providers/provider/ProvidersSingleSubscriptionUpdatePaymentMethod.vue'),
        meta: {
          isA: 'provider',
        },
      },
      {
        path: 'subscription/invoice/:invoiceId',
        name: 'providers.single.subscription.invoice',
        component: () => import(/* webpackChunkName: "providers" */ '@/views/providers/provider/ProvidersSingleSubscriptionInvoiceSingle.vue'),
        meta: {
          isA: 'provider',
        },
      }
    ],
  },
  {
    path: '/providers/:providerId',
    name: 'providers.show',
    component: () => import(/* webpackChunkName: "providers" */ '@/views/providers/ProvidersSingle.js'),
    props: ({ params }) => ({ providerId: toNumber(params.providerId) }),
    children: [
      {
        path: '/providers/:providerId/courses',
        name: 'providers.single.courses',
        component: () => import(/* webpackChunkName: "providers" */ '@/views/providers/ProvidersSingleCourses')
      },
      {
        path: '/providers/:providerId',
        name: 'providers.single.details',
        component: () => import(/* webpackChunkName: "providers" */ '@/views/providers/ProvidersSingleDetails')
      },
      {
        path: '/providers/:providerId/learner-quality-score',
        name: 'admin.providers.single.learner-quality-score',
        component: () => import(/* webpackChunkName: "providers" */ '@/views/providers/ProvidersSingleLearnerQualityScore'),
        meta: {
          isA: 'admin',
        },
      },
      {
        path: '/providers/:providerId/users',
        name: 'providers.single.users',
        component: () => import(/* webpackChunkName: "providers" */ '@/views/providers/ProvidersSingleUsers')
      },
      {
        path: '/providers/:providerId/users/add',
        name: 'providers.single.users.add',
        meta: {
          can: 'write users'
        },
        component: () => import(/* webpackChunkName: "providers" */ '@/views/providers/admin/ProviderSingleUsersAdd')
      },
      {
        path: '/providers/:providerId/subscription',
        name: 'admin.providers.single.subscription',
        component: () => import(/* webpackChunkName: "providers" */ '@/views/providers/provider/ProvidersSingleSubscription.vue'),
        meta: {
          isA: 'admin',
        },
      },
      {
        path: '/providers/:providerId/subscription/subscribe',
        name: 'admin.providers.single.subscription.subscribe',
        component: () => import(/* webpackChunkName: "providers" */ '@/views/providers/admin/ProvidersSingleSubscriptionChangePlanCard.vue'),
        meta: {
          isA: 'admin',
        },
      },
      {
        path: '/providers/:providerId/subscription/invoice/:invoiceId',
        name: 'admin.providers.single.subscription.invoice',
        component: () => import(/* webpackChunkName: "providers" */ '@/views/providers/provider/ProvidersSingleSubscriptionInvoiceSingle.vue'),
        meta: {
          isA: 'admin',
        },
      },
      {
        path: '/providers/:providerId/subscription/billing-email',
        name: 'admin.providers.single.subscription.billing-email',
        component: () => import(/* webpackChunkName: "providers" */ '@/views/providers/provider/ProvidersSingleSubscriptionBillingEmailCard.vue'),
        meta: {
          isA: 'admin',
        },
      },
    ]
  }
])
