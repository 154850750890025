import Vue from 'vue'
import { get } from 'vuex-pathify'

Vue.mixin({
  computed: {
    ...get('auth', {
      $user: 'user',
      $can: 'can',
      $cannot: 'cannot',
      $isA: 'isA',
      $isAn: 'isA',
      $isNotA: 'isNotA'
    })
  }
})

export {
  //
}
