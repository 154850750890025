import { applyRouteMeta } from '@codium/codium-vue'

export default applyRouteMeta({
  template: 'app',
  requiresAuth: true
}, [
  {
    path: '/reports',
    name: 'reports',
    meta: {
      can: 'read reports'
    },
    component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/Reports')
  },
])
