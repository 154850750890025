import { applyRouteMeta } from '@codium/codium-vue'

export default applyRouteMeta({
  template: 'app',
  requiresAuth: true
}, [
  {
    path: '/subscribers',
    name: 'subscribers',
    meta: {
      isA: 'admin',
    },
    component: () => import(/* webpackChunkName: "users" */ '@/views/subscribers/SubscribersList.vue')
  },
])
