import { makeModule } from '@codium/codium-vue'
import { http } from '@/utils'

export const storeModule = makeModule('courses', {
  parent: 'learningPlans',

  actions: {
    async storeLearningPlanCourse (store, { id, courseId }) {
      await http.put(`learning-plans/${id}/courses/${courseId}`)
    },

    async deleteLearningPlanCourse (store, { id, courseId }) {
      await http.delete(`learning-plans/${id}/courses/${courseId}`)
    }
  }
})
