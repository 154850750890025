import http, { apiUrl } from './http'
import auth from './auth'
import alert from './alert'
export { handleServerConflict } from './functions/handle-server-conflict'

const id = value => value.id || value

const pagination = (pagination, params = {}, fresh = false) => {
  const newParams = { ...params }
  if (fresh) {
    newParams.page = 1

    return newParams
  }

  const error = new Error('no more results')

  if (pagination) {
    if (
      pagination.current_page !== undefined &&
      pagination.last_page !== undefined &&
      pagination.current_page === pagination.last_page
    ) {
      throw error
    } else if (
      pagination.from === undefined ||
      pagination.to === undefined ||
      pagination.from === null ||
      pagination.to === null ||
      pagination.to - (pagination.from - 1) < pagination.per_page
    ) {
      throw error
    }

    newParams.page = pagination.current_page + 1
  }

  return newParams
}

export {
  http,
  apiUrl,
  id,
  pagination,
  auth,
  alert
}
