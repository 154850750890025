import { makeResource, mapped } from '@codium/codium-vue'

export const { storeModule, stateMapper, helperMethods, helperComputed } = makeResource('providers/users', {
  resource () {
    return {
      id: mapped(null),
      title: mapped(null),
      first_name: mapped(null),
      last_name: mapped(null),
      name: mapped(null),
      email: mapped(null),
      new_email: mapped(null),
      mobile: mapped(null),
      status: mapped(null),
      abilities: mapped(null),
      role: mapped(null),
      last_login_at: mapped(null),
      last_login_ip: mapped(null),
      two_factor_required: mapped(null),
      two_factor_method_preferred: mapped(null),
      receive_marketing: mapped(false),
      participate_feedback: mapped(false),
    }
  }
})
