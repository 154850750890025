import { applyRouteMeta } from '@codium/codium-vue'

export default [
  ...applyRouteMeta({
    template: 'auth',
    requiresAuth: false
  }, [
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Login')
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/ForgotPassword')
    },
    {
      path: '/verification/:signature?',
      name: 'verification',
      props: true,
      component: () => import(/* webpackChunkName: "register" */ '@/views/auth/AccountVerification'),
      meta: {
        sometimesAuth: true
      }
    },
    {
      path: '/set-password/:token',
      name: 'set-password',
      props: true,
      component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/SetPassword')
    }
  ]),
  ...applyRouteMeta({
    template: 'auth',
    requiresAuth: true
  }, [
    {
      path: '/two-factor/verification',
      name: 'two-factor.verification',
      component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/TwoFactorVerify')
    },
    {
      path: '/two-factor/setup',
      name: 'two-factor.setup',
      component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/TwoFactorSetup')
    },
    {
      path: '/two-factor/setup/app',
      name: 'two-factor.setup.app',
      component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/TwoFactorSetupApp')
    },
    {
      path: '/two-factor/setup/email',
      name: 'two-factor.setup.email',
      component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/TwoFactorSetupEmail')
    },
    {
      path: '/register/subscribe',
      name: 'register.subscribe',
      component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/subscriptions/Subscribe')
    }
  ]),
  ...applyRouteMeta({
    template: 'app',
    requiresAuth: true
  }, [
    {
      path: '/logout',
      name: 'logout',
      component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Logout')
    }
  ])
]
