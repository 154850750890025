<template>
  <div id="app">
    <header class="public">
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
          <a class="navbar-brand" href="#">
            <img
              src="@/assets/img/logo/primary-stacked.svg"
              alt="Med CPD"
              width="400"
              height="70"
            />
          </a>

          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarContent"
            aria-controls="navbarContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarContent">
            <ul class="navbar-nav">
              <li class="nav-item">
                <router-link :to="{ name: 'home' }" class="nav-link" exact>
                  Home
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'about' }" class="nav-link" exact>
                  About
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'provider' }" class="nav-link" exact>
                  Provider
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'contact' }" class="nav-link" exact>
                  Contact
                </router-link>
              </li>
            </ul>

            <div class="nav-actions" v-if="!$user.id">
              <router-link :to="{ name: 'login' }" class="btn btn-md btn-login">Login</router-link>
              <router-link :to="{ name: 'register-user' }" class="btn btn-md btn-primary">Register</router-link>
            </div>
            <div v-else class="nav-actions">
              <router-link :to="{ name: 'dashboard' }" class="btn btn-md btn-login">My Dashboard</router-link>
            </div>
          </div>
        </div>
      </nav>
    </header>

    <router-view/>
    <app-footer/>
  </div>
</template>

<script>
import AppFooter from '@/components/app/AppFooterPublic'
export default {
  components: {
    AppFooter
  },
  mounted () {
    document.body.classList.add('public')
  },

  beforeDestroy () {
    document.body.classList.remove('public')
  }
}
</script>
