import { applyRouteMeta } from '@codium/codium-vue'

export default applyRouteMeta({
  template: 'app',
  requiresAuth: true
}, [
  {
    path: '/questionnaire/:courseUuid',
    name: 'questionnaire',
    meta: {},
    component: () => import(/* webpackChunkName: "questionnaire" */ '@/views/Questionnaires')
  },
])
