import { makeResource, mapped, http } from '@codium/codium-vue'
import { storeModule as learningPlans } from './users/learning-plans'
import { storeModule as professionalDevelopmentPlans } from './users/professional-development-plans'
import { storeModule as accreditationBodies } from './users/accreditation-bodies'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('users', {
  storeModule: {
    modules: {
      learningPlans,
      professionalDevelopmentPlans,
      accreditationBodies
    },

    getters: {
      isA: (state, getters) => (role) => state.user.role.name === role,
      isAn: (state, getters) => (role) => getters.isA(role),
    },

    actions: {
      storeUserActivity (store, { activity, subjectId, subjectType, properties }) {
        return http.post('user/activity', {
          activity,
          subject_id: subjectId,
          subject_type: subjectType,
          properties
        })
      },

      onboardUser ({ state }, { id, payload }) {
        return http.post(`users/${id}/onboard`, payload)
      },

      async toggleStatus ({ dispatch, getters }, { id }) {
        const response = await http.put(`users/${id}/toggle-status`)
        dispatch('setUser', response.data.data)
      },
    }
  },

  resource () {
    return {
      id: mapped(null),
      title: mapped(null),
      first_name: mapped(null),
      last_name: mapped(null),
      name: mapped(null),
      email: mapped(null),
      dob: mapped(null),
      location: mapped(null),
      country_of_study_id: mapped(null),
      university_id: mapped(null),
      cpd_body_id: mapped(null),
      profession_id: mapped(null),
      primary_field_id: mapped(null),
      provider: mapped(null),
      public_sector: mapped(null),
      private_sector: mapped(null),
      qualifications: mapped([]),
      new_email: mapped(null),
      mobile: mapped(null),
      topics: mapped([]),
      status: mapped(null),
      abilities: mapped(null),
      role: mapped({}),
      last_login_at: mapped(null),
      last_login_ip: mapped(null),
      two_factor_required: mapped(null),
      two_factor_method_preferred: mapped(null),
      receive_marketing: mapped(false),
      participate_feedback: mapped(false),
    }
  }
})
