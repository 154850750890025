<template>
  <router-link v-bind="$attrs" custom v-slot="{ href, isActive, isExactActive, navigate }" @click.native="toggleSidebar">
    <li class="nav-item" :class="{ active: exact ? isExactActive : isActive }" @click="navigate">
      <a class="nav-link" v-bind="{ href }">
        <i class="far fa-fw" :class="`fa-${icon}`"></i>

        <span><slot/></span>
      </a>
    </li>
  </router-link>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    icon: String,
    exact: Boolean
  },

  methods: {
    toggleSidebar () {
      // Abort closing sidebar if screen is larger than 770px
      if (screen.width > 770) {
        return
      }

      const wrapper = document.getElementById('wrapper')

      wrapper.classList.toggle('toggled')
      wrapper.classList.add('transitioning')

      setTimeout(() => {
        wrapper.classList.remove('transitioning')
      }, 300)
    }
  }
}
</script>
