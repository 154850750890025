import { makeResource, mapped } from '@codium/codium-vue'
import { storeModule as pricings } from './packages/pricings'
import { http } from '@/utils'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('packages', {
  storeModule: {
    modules: {
      pricings,
    },

    actions: {
      async getPackages ({ dispatch }, params) {
        const response = await http.get('/subscription-plans', params ? { params: params } : { })

        if (response.data.data.length === 1) {
          dispatch('setPackage', response.data.data[0])
          return
        }

        dispatch('setPackages', response.data.data)
      }
    }
  },

  resource () {
    return {
      id: mapped(null),
      name: mapped(null),
      description: mapped(null),
      pricings: mapped([])
    }
  }
})
