export default [
  {
    path: '/register',
    name: 'register-user',
    meta: {
      template: 'blank',
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "register" */ '@/views/register/Register')
  },
  {
    path: '/register/onboard',
    name: 'register-user-onboard',
    meta: {
      template: 'blank',
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "register" */ '@/views/register/OnboardUser')
  },
  {
    path: '/register-provider',
    name: 'register-provider',
    meta: {
      template: 'blank',
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "register" */ '@/views/register/RegisterProvider')
  },
  {
    path: '/register-provider/onboard',
    name: 'register-provider-onboard',
    meta: {
      template: 'blank',
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "register" */ '@/views/register/OnboardProvider')
  },
  {
    path: '/onboarding/complete/:token?',
    name: 'onboarding.complete',
    meta: {
      template: 'blank',
      sometimesAuth: false
    },
    props: true,
    component: () => import(/* webpackChunkName: "register" */ '@/views/register/RegisterOnboardingComplete')
  },
]
