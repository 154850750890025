import { extend, localize, configure } from 'vee-validate'
import isURL from 'validator/lib/isURL'

export default () => {
  configure({
    mode: 'eager',
    classes: {
      valid: 'is-valid',
      invalid: 'is-invalid'
    }
  })

  extend('url', {
    validate: value => isURL(value),
    message: 'The {_field_} must be a valid URL'
  })

  extend('contains_special_character', {
    validate: value => /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value),
    message: 'The {_field_} must contain a special characater'
  })

  extend('contains_number', {
    validate: value => /\d/.test(value),
    message: 'The {_field_} must contain a number'
  })

  extend('contains_uc_character', {
    validate: value => /[A-Z]+/.test(value),
    message: 'The {_field_} must contain an upper case letter'
  })

  extend('contains_lc_character', {
    validate: value => /[a-z]+/.test(value),
    message: 'The {_field_} must contain a lower case letter'
  })

  extend('phone', {
    validate: (value) => {
      return /\d/.test(value) && value.length === 10
    },
    message: 'The {_field_} must be a valid phone number'
  })

  extend('decimal', {
    validate: (value, { decimals = '*', separator = '.' } = {}) => {
      if (value === null || value === undefined || value === '' || value < 0) {
        return {
          valid: false
        }
      }
      if (Number(decimals) === 0) {
        return {
          valid: /^-?\d*$/.test(value),
        }
      }
      const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`
      const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`)

      return {
        valid: regex.test(value),
      }
    },
    message: 'The {_field_} field must be numeric or decimal'
  })

  localize({
    en: {
      fields: {
        'password confirmation': {
          is: 'The password confirmation does not match'
        }
      }
    }
  })
}
