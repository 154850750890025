import { makeResource, mapped } from '@codium/codium-vue'
import { http } from '@/utils'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('questionnaireQuestions', {
  storeModule: {
    actions: {
      async storeQuestionnaireResponse ({ dispatch, getters }, payload) {
        await http.post('submit-questionnaire', payload)
      },
    },
  },
  resource () {
    return {
      id: mapped(null),
      category: mapped(null),
      title: mapped(null),
      description: mapped(null),
      score: mapped(null)
    }
  }
})
