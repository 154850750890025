import store from '@/vuex/store'

export default async (to, from, next) => {
  const { user } = store.state.auth
  const isA = store.getters['auth/isA']

  if (to.name === 'requires-subscription') {
    return next()
  }

  if (user && user.role && isA('user') && !user.has_subscription && to.meta.requiresSubscription) {
    return next({ name: 'requires-subscription' })
  }

  next()
}
