import { http } from '@/utils'
import { get, call, make } from 'vuex-pathify'
import { PureAbility } from '@casl/ability'

export const helperComputed = get('auth', ['user', 'twoFactorProvisioningUri', 'twoFactorSecret'])
export const helperMethods = call('auth', [
  'authorize',
  'login',
  'logout',
  'forgotPassword',
  'resetPassword',
  'updateTwoFactor',
  'sendTwoFactorEmailCode',
  'verifyTwoFactorCode',
  'getTwoFactorSecret'
])

const state = {
  user: {},
  attempted: false,
  twoFactorProvisioningUri: null,
  twoFactorSecret: null
}

const getters = {
  ability () {
    return new PureAbility([])
  },
  can: (state, getters) => (ability) => {
    return getters.ability.can(ability)
  },
  cannot: (state, getters) => (ability) => !getters.can(ability),
  isA: (state, getters) => (role) => state.user.role.name === role,
  isNotA: (state, getters) => (role) => state.user.role.name !== role
}

const actions = {
  ...make.actions(state),

  login ({ dispatch }, { email, password, headers }) {
    dispatch('setAttempted', false)

    return http.post('/auth', { email, password }, { headers })
  },

  async authorize ({ dispatch }) {
    dispatch('setAttempted', true)

    const response = await http.get('/user', { authorizePreflight: true })
    const user = response.data.data

    // If user is a provider we'll set the provider in the response as the provider within Vuex.
    if (user.role.name === 'provider') {
      dispatch('providers/setProvider', user.provider, { root: true })

      delete user.provider
    }

    dispatch('setUser', user)

    return response
  },

  async logout ({ dispatch, commit }) {
    dispatch('setAttempted', false)

    await http.delete('/auth')

    commit('RESET_USER')
    dispatch('app/setCsrf', false, { root: true })
  },

  async forgotPassword ({ commit }, payload) {
    return await http.post('/password/forgot', payload)
  },

  async resetPassword ({ commit }, payload) {
    return await http.post('/password/reset', payload)
  },

  async updateTwoFactor ({ dispatch, state }, { id, payload }) {
    const response = await http.put(`/users/${id || state.user.id}/two-factor`, payload)

    if (!id) {
      dispatch('setUser', response.data.data)
    }

    return response.data.data
  },

  async invalidateTwoFactorAppToken ({ dispatch, state }) {
    const response = await http.put('/auth/two-factor/invalidate')
    dispatch('setUser', response.data.data)
  },

  sendTwoFactorEmailCode () {
    return http.post('/auth/two-factor/email')
  },

  verifyTwoFactorCode (store, { code, method, secret }) {
    return http.post('/auth/two-factor/verify', { code, method, secret })
  },

  async getTwoFactorSecret ({ commit }) {
    const response = await http.get('/auth/two-factor/secret')

    commit('SET_TWO_FACTOR_PAYLOAD', response.data.data)
  }
}

const mutations = {
  ...make.mutations(state),

  RESET_USER (state) {
    state.user = {}
  },

  SET_TWO_FACTOR_PAYLOAD (state, payload) {
    state.twoFactorProvisioningUri = payload.provisioning_uri
    state.twoFactorSecret = payload.secret
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
