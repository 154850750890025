<template>
  <div id="sidebar-wrapper">
    <div class="top">
      <img class="logo" src="@/assets/img/logo/primary.svg" alt="Med CPD" />
    </div>

    <div class="middle">
      <vue-scroll :ops="{ rail: { gutterOfSide: '1px', gutterOfEnds: '0px' }, bar: { size: '4px' } }">
        <ul class="nav">
          <sidebar-item :to="{ name: 'dashboard' }" icon="home">
            Home
          </sidebar-item>
          <sidebar-item :to="{ name: 'courses' }" icon="search">
            Course Search
            <span class="count">{{ coursesCount }}</span>
          </sidebar-item>
          <sidebar-item :to="{ name: 'providers' }" icon="university">
            Training Providers
          </sidebar-item>
          <sidebar-item :to="{ name: 'subscribers' }" icon="file-invoice-dollar">
            Subscribers
          </sidebar-item>
          <sidebar-item :to="{ name: 'learning-plans' }" icon="book-open">
            Learning Plans
          </sidebar-item>
          <sidebar-item :to="{ name: 'users' }" icon="users">
            Users
          </sidebar-item>
          <sidebar-item :to="{ name: 'reports' }" icon="analytics">
            Reports
          </sidebar-item>
          <sidebar-item :to="{ name: 'settings' }" icon="cogs">
            System Settings
          </sidebar-item>
          <sidebar-item :to="{ name: 'my.account' }" icon="user-circle">
            My Account
          </sidebar-item>
          <li class="nav-item">
            <a
              href="https://medcpd.com/blog/"
              target="_blank"
              class="nav-link"
              ><i class="far fa-blog"></i>Blog</a>
          </li>
          <sidebar-item :to="{ name: 'logout' }" icon="sign-out">
            Logout
          </sidebar-item>
          <!-- <sidebar-item :to="{ name: 'email-templates' }" icon="envelope">
            Email Templates
          </sidebar-item> -->
        </ul>
      </vue-scroll>
    </div>
  </div>
</template>

<script>
import SidebarItem from '../AppSidebarItem'
import { http } from '@/utils'

export default {
  components: {
    SidebarItem
  },

  data () {
    return {
      coursesPendingApproval: 0
    }
  },

  computed: {
    coursesCount () {
      return this.coursesPendingApproval < 100 ? this.coursesPendingApproval : '99+'
    }
  },

  async created () {
    const response = await http.get('courses/pending-approval')
    this.coursesPendingApproval = response.data
  }
}
</script>
