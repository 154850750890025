import { makeResource, mapped } from '@codium/codium-vue'

export const { storeModule, stateMapper, helperMethods, helperComputed } = makeResource('subscribers', {
  resource () {
    return {
      id: mapped(null),
      type: mapped(null),
      name: mapped(null),
      package_name: mapped(null),
      billing_frequency: mapped(null),
      price: mapped(0),
      status: mapped(null),
    }
  }
})
