import { makeResource, mapped } from '@codium/codium-vue'

export const { storeModule, stateMapper } = makeResource('professionalDevelopmentPlans', {
  storeModule: {
  },

  resource () {
    return {
      id: mapped(null),
      name: mapped(null),
      year: mapped(null),
      updated_at: mapped(null),
      practice: mapped(null),
      area_of_interest: mapped(null),
      strength: mapped(null),
      weakness: mapped(null),
      aspirations: mapped(null),
    }
  }
})
