<template>
  <div id="sidebar-wrapper">
    <div class="top">
      <img class="logo" src="@/assets/img/logo/primary.svg" alt="Med CPD" />
    </div>

    <div class="middle">
      <vue-scroll :ops="{ rail: { gutterOfSide: '1px', gutterOfEnds: '0px' }, bar: { size: '4px' } }">
        <ul class="nav">
          <sidebar-item :to="{ name: 'dashboard' }" icon="home">
            Home
          </sidebar-item>
          <sidebar-item :to="{ name: 'courses' }" icon="search">
            Course Search
          </sidebar-item>
          <sidebar-item :to="{ name: 'topics' }" icon="books">
            Browse By Topic
          </sidebar-item>
          <sidebar-item :to="{ name: 'learning-plans' }" icon="book-open">
            Learning Plans
          </sidebar-item>
          <sidebar-item :to="{ name: 'providers' }" icon="university">
            Training Providers
          </sidebar-item>
          <sidebar-item :to="{ name: 'my.plans.learning-plans' }" icon="book-open">
            My Learning Plans
          </sidebar-item>
          <sidebar-item :to="{ name: 'my.account' }" icon="user-circle">
            My Account
          </sidebar-item>
          <li class="nav-item">
            <a
              href="https://medcpd.com/blog/"
              target="_blank"
              class="nav-link"
              ><i class="far fa-blog"></i>Blog</a>
          </li>
          <sidebar-item :to="{ name: 'logout' }" icon="sign-out">
            Logout
          </sidebar-item>
        </ul>
      </vue-scroll>
    </div>
  </div>
</template>

<script>
import SidebarItem from '../AppSidebarItem'

export default {
  components: {
    SidebarItem
  }
}
</script>
