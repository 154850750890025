import { makeModule } from '@codium/codium-vue'

export const storeModule = makeModule('learningPlans', {
  parent: 'users',
  actions: {
    updateCoursesCount ({ commit }, { id, count }) {
      commit('UPDATE_COURSES_COUNT', { id, count })
    }
  },

  mutations: {
    UPDATE_COURSES_COUNT (state, { id, count }) {
      const index = state.learningPlans.findIndex(learningPlan => learningPlan.id === id)

      state.learningPlans[index].courses_count = count
    }
  }
})
