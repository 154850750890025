import { makeResource, mapped } from '@codium/codium-vue'
import { http } from '@/utils'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('activeSubscriptions', {
  storeModule: {
    actions: {
      async updateBillingEmail ({ commit }, { payload, providerId }) {
        await http.put(`/providers/${providerId}/subscription/update-email`, payload)
        commit('SET_BILLING_EMAIL', payload)
      },

      async getActiveSubscription ({ commit }, { type, id }) {
        const response = type && id
          ? await http.get(`/${type}/${id}/active-subscription`)
          : await http.get('/active-subscriptions')
        commit('SET_ACTIVE_SUBSCRIPTION', response.data.data)
        return response.data.data
      }
    },

    mutations: {
      SET_BILLING_EMAIL (state, { billingEmail }) {
        state.billing_email = billingEmail
      },
    }
  },

  resource () {
    return {
      id: mapped(null),
      package_pricing_id: mapped(null),
      billing_frequency: mapped(null),
      billing_frequency_label: mapped(null),
      price: mapped(null),
      current_period_end: mapped(null),
      ends_at: mapped(null),
      payment_method: mapped(null),
      billing_emails: mapped([]),
    }
  }
})
