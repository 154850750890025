import Admin from './admin/AppSidebar.vue'
import Provider from './provider/AppSidebar.vue'
import User from './user/AppSidebar.vue'

const components = {
  admin: Admin,
  provider: Provider,
  user: User,
}

export default {
  functional: true,

  render (h, context) {
    const { role } = context.parent.$store.state.auth.user

    if (!role || !role.name) {
      return
    }

    return h(components[role.name], context.data, context.children)
  }
}
