<template>
  <div id="app">
    <div id="wrapper">
      <sidebar />

      <div id="page-content-wrapper">
        <router-view>
          <template #footer>
            <app-footer-main />
          </template>
        </router-view>
      </div>
    </div>

    <portal-target name="modals"/>
  </div>
</template>

<script>
import Sidebar from '@/components/app/AppSidebar'
import AppFooterMain from '@/components/app/AppFooterMain'

export default {
  components: {
    Sidebar,
    AppFooterMain
  },

  mounted () {
    const contentWrapper = document.getElementById('page-content-wrapper')

    contentWrapper.addEventListener('click', function () {
      const wrapper = document.getElementById('wrapper')

      // Abort closing sidebar if screen is larger than 770px or the sidebar is not open
      if (screen.width > 770 || wrapper.classList.contains('transitioning') || !wrapper.classList.contains('toggled')) {
        return
      }

      wrapper.classList.remove('toggled')
      wrapper.classList.add('transitioning')

      setTimeout(() => {
        wrapper.classList.remove('transitioning')
      }, 300)
    })
  }
}
</script>
