import { makeResource, mapped } from '@codium/codium-vue'
import { http } from '@/utils'
import { storeModule as courses } from './learning-plans/courses'

export const { storeModule, stateMapper } = makeResource('learningPlans', {
  storeModule: {
    modules: {
      courses
    },

    actions: {
      async updateLearningPlan ({ getters, dispatch }, { id, payload, headers }) {
        const response = await http.put(getters.uri(id), payload, { headers })

        dispatch('setLearningPlan', response.data.data)
        dispatch('replaceLearningPlan', response.data.data)
      }
    }
  },

  resource () {
    return {
      id: mapped(null),
      name: mapped(null),
      visibility: mapped(null),
      short_description: mapped(null),
      description: mapped(null),
      professions: mapped([]),
      topics: mapped([]),
      primary_fields: mapped([]),
    }
  }
})
