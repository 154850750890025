<template>
  <header class="page-header">
    <div class="left">
      <button class="btn" id="menu-toggle" @click="toggleSidebar">
        <i class="fal fa-bars"></i>
      </button>
    </div>
    <div class="middle">
      <div class="page-title">
        <h1>
          {{ title }}
        </h1>
      </div>

      <div class="actions">
        <slot name="actions" />
        <portal-target name="header" />
      </div>
    </div>

    <portal-target name="headerRight" class="right"/>
  </header>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    }
  },

  methods: {
    toggleSidebar () {
      const wrapper = document.getElementById('wrapper')

      wrapper.classList.toggle('toggled')
      wrapper.classList.add('transitioning')

      setTimeout(() => {
        wrapper.classList.remove('transitioning')
      }, 300)
    }
  }
}
</script>
