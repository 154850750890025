import { makeResource, mapped } from '@codium/codium-vue'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('professions', {
  resource () {
    return {
      id: mapped(null),
      name: mapped(null),
      status: mapped(null)
    }
  }
})
