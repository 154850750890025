import { applyRouteMeta } from '@codium/codium-vue'
import { toNumber } from 'lodash'

export default applyRouteMeta({
  template: 'app',
  requiresAuth: true,
  can: 'read learning plans'
}, [
  {
    path: '/learning-plans',
    name: 'learning-plans',
    component: () => import(/* webpackChunkName: "learningPlans" */ '@/views/learning-plans/LearningPlansList.vue'),
    meta: {
      requiresSubscription: true
    },
  },
  {
    path: '/learning-plans/:learningPlanId',
    name: 'learning-plans.single',
    props: ({ params }) => ({ learningPlanId: toNumber(params.learningPlanId) }),
    component: () => import(/* webpackChunkName: "learningPlans" */ '@/views/learning-plans/LearningPlansSingle'),
    meta: {
      requiresSubscription: true
    },
  },
  {
    path: '/requires-subscription',
    name: 'requires-subscription',
    component: () => import(/* webpackChunkName: "learningPlans" */ '@/views/subscriptions/RequiresSubscription.vue')
  },
])
