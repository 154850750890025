import { makeResource, mapped } from '@codium/codium-vue'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('topics', {
  resource () {
    return {
      id: mapped(null),
      name: mapped(null),
      status: mapped(null),
      courses_count: mapped(null),
      profession_id: mapped(null),
      children: mapped([]),
    }
  }
})
