import { makeResource, mapped } from '@codium/codium-vue'
import { http } from '@/utils'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('universities', {
  storeModule: {
    actions: {
      async toggleStatus ({ dispatch, state, getters }, { id }) {
        const response = await http.put(`${getters.uri(`${id}`)}/toggle-status`)

        dispatch('setUniversity', response.data.data)
        dispatch('removeUniversity', state.university)
      },
    }
  },
  resource () {
    return {
      id: mapped(null),
      name: mapped(null),
      country_id: mapped(null),
      status: mapped('published')
    }
  }
})
