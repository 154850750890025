import { makeResource, mapped } from '@codium/codium-vue'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('package/pricings', {
  resource () {
    return {
      id: mapped(null),
      package_id: mapped(null),
      price: mapped(0),
      billing_frequency: mapped(null),
    }
  }
})
