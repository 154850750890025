import Vue from 'vue'
import router from './router'
import store from './vuex/store'
import App from './App.vue'
import Codium from '@codium/codium-vue'
import Chameleon from '@chameleon/chameleon-vue'
import VueScroll from 'vuescroll'
import PortalVue from 'portal-vue'

/**
 * Import other dependencies.
 */
import '@/mixins'
import '@/filters'
import '@/utils/font-awesome'
import configureValidation from '@/utils/validation'
import '@/utils/components'
import 'bootstrap'
import VueGtag from 'vue-gtag'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

/**
 * Import theme SCSS.
 */
import 'vue-multiselect/dist/vue-multiselect.min.css'
import '@/assets/scss/app.scss'

Vue.config.productionTip = false

Vue.use(Codium)
Vue.use(Chameleon)
Vue.use(PortalVue)
Vue.use(VueScroll, {
  ops: {
    rail: {
      gutterOfSide: '4px',
      gutterOfEnds: '6px'
    },
    bar: {
      background: '#4691C2'
    }
  }
})

configureValidation()
export const bus = new Vue()

// Configure Google Analytics
if (process.env.VUE_APP_GOOGLE_TRACKING_ID) {
  Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_GOOGLE_TRACKING_ID }
  }, router)
}

Vue.component('VueSlider', VueSlider)

export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
