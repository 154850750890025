import { castArray } from 'lodash'
import swal from 'sweetalert'

export const handleServerConflict = async (callback) => {
  let headers = {}

  while (true) {
    try {
      await callback(headers)

      if (swal.getState().isOpen) {
        swal.stopLoading()
        swal.close()
      }

      return
    } catch (error) {
      if (!error.response || (error.response && error.response.status !== 409)) {
        throw error
      }

      const { title, message, buttons } = error.response.data

      const hash = await swal({
        title,
        text: message,
        buttons: {
          cancel: {
            visible: true,
            text: buttons.cancel.label,
            value: buttons.cancel.hash,
            className: buttons.cancel.class
          },
          confirm: {
            closeModal: false,
            text: buttons.ok.label,
            value: buttons.ok.hash,
            className: buttons.ok.class
          }
        },
        dangerMode: true
      })

      const hashes = castArray(error.response.headers['x-conflict-resolution-hash'] || [])

      if (hash) {
        headers = {
          'X-Conflict-Resolution-Hash': [hash, ...hashes].join(', ')
        }
      } else {
        return
      }
    }
  }
}
