<template>
  <div id="app">
    <div class="error">
      <router-view class="container container-max-sm my-auto py-5" />
    </div>
  </div>
</template>

<script>
export default {
}
</script>
