import { makeResource, mapped } from '@codium/codium-vue'
import { http } from '@/utils'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('questionnaires', {
  storeModule: {
    actions: {
      async storeQuestionnaireResponse ({ dispatch, getters }, payload) {
        await http.post('submit-questionnaire', payload)
      },

      async getQuestionnaire ({ dispatch, getters }, payload) {
        const response = await http.get('questionnaire', { params: { ...payload } })

        dispatch('setQuestionnaire', response.data.data)
      },
    },
  },
  resource () {
    return {
      id: mapped(null),
    }
  }
})
