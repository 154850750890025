import { makeResource, mapped } from '@codium/codium-vue'
import { storeModule as courses } from './providers/courses'
import { storeModule as users } from './providers/users'
import { http } from '@/utils'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('providers', {
  storeModule: {
    modules: {
      courses,
      users,
    },

    actions: {
      async updateProvider ({ getters, dispatch }, { id, payload }) {
        const response = await http.post(getters.uri(id), payload)

        dispatch('setProvider', response.data.data)
      },

      async deleteProviderFeaturedImage ({ getters, state, dispatch }) {
        await http.delete(`${getters.uri(':id')}/featured-image`)

        dispatch('setProvider', {
          ...state.provider,
          featured_image_url: null,
          featured_image: null,
        })
      },

      async deleteProviderSquareLogo ({ getters, state, dispatch }) {
        await http.delete(`${getters.uri(':id')}/square-logo`)

        dispatch('setProvider', {
          ...state.provider,
          square_logo_url: null,
          square_logo: null,
        })
      },

      async deleteProviderHorizontalLogo ({ getters, state, dispatch }) {
        await http.delete(`${getters.uri(':id')}/horizontal-logo`)

        dispatch('setProvider', {
          ...state.provider,
          horizontal_logo_url: null,
          horizontal_logo: null,
        })
      },

      async updateProviderSettings ({ getters }, { id, payload }) {
        await http.put(`${getters.uri(id)}/settings`, payload)
      }
    }
  },
  resource () {
    return {
      id: mapped(null),
      name: mapped(null),
      provider_acronym: mapped(null),
      status: mapped(null),
      phone_number: mapped(null),
      email: mapped(null),
      website: mapped(null),
      short_description: mapped(null),
      long_description: mapped(null),
      requires_course_review: mapped(true),
      reports_access: mapped(true),
      address: {
        formatted_address: mapped(null),
        street: mapped(null),
        suburb: mapped(null),
        state: mapped(null),
        state_short: mapped(null),
        postcode: mapped(null),
        country: mapped(null),
        latitude: mapped(null),
        longitude: mapped(null)
      }
    }
  }
})
