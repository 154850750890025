import { applyRouteMeta } from '@codium/codium-vue'

export default applyRouteMeta({
  template: 'public',
  sometimesAuth: true
}, [
  {
    path: '/',
    name: 'home',
    redirect: to => {
      return { name: 'login' }
    }
  },
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import(/* webpackChunkName: "public" */ '@/views/public/Home.vue')
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(/* webpackChunkName: "public" */ '@/views/public/About.vue')
  // },
  // {
  //   path: '/provider',
  //   name: 'provider',
  //   component: () => import(/* webpackChunkName: "public" */ '@/views/public/Provider.vue')
  // },
  // {
  //   path: '/contact',
  //   name: 'contact',
  //   component: () => import(/* webpackChunkName: "public" */ '@/views/public/Contact.vue')
  // },
  // {
  //   path: '/p/courses/:courseId',
  //   name: 'public.course',
  //   props: true,
  //   component: () => import(/* webpackChunkName: "public" */ '@/views/public/Course.vue')
  // },
  // {
  //   path: '/p/learning-plans/:learningPlanId',
  //   name: 'public.learning-plan',
  //   props: true,
  //   component: () => import(/* webpackChunkName: "public" */ '@/views/public/LearningPlan.vue')
  // },
  {
    path: '/terms',
    name: 'terms',
    props: true,
    component: () => import(/* webpackChunkName: "public" */ '@/views/public/Terms.vue')
  }
])
