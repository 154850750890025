import { makeModule } from '@codium/codium-vue'
import { storeModule as providers } from './lists/providers'
import { storeModule as topics } from './lists/topics'
import { storeModule as countries } from './lists/countries'
import { storeModule as universities } from './lists/universities'
import { storeModule as cpdBodies } from './lists/cpd-bodies'
import { storeModule as primaryFields } from './lists/primary-fields'
import { storeModule as subscriptionPlans } from './lists/subscription-plans'
import { storeModule as professions } from './lists/professions'
import { storeModule as qualifications } from './lists/qualifications'

export const storeModule = makeModule('lists', {
  modules: {
    providers,
    topics,
    countries,
    universities,
    cpdBodies,
    primaryFields,
    subscriptionPlans,
    professions,
    qualifications,
  }
})
