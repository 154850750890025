import { makeResource, mapped } from '@codium/codium-vue'
import { http } from '@/utils'

export const { storeModule, stateMapper } = makeResource('providers/courses', {
  storeModule: {
    actions: {
      async getRecentlyPublishedCourses ({ getters, dispatch }) {
        const response = await http.get(`${getters.uri()}/recent`)

        dispatch('setCourses', response.data.data)
      }
    }
  },
  resource () {
    return {
      id: mapped(null),
      name: mapped(null),
    }
  }
})
