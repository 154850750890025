import { makeResource, mapped } from '@codium/codium-vue'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('cpdBodies', {
  customSingular: 'cpdBody',
  resource () {
    return {
      id: mapped(null),
      name: mapped(null),
      profession_id: mapped(null),
      archived: mapped(false),
    }
  }
})
