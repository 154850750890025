import store from '@/vuex/store'

export default async (to, from, next) => {
  const { auth, app } = store.state
  const isA = store.getters['auth/isA']
  const requiresAuth = to.matched.some(route => route.meta.requiresAuth === true)
  const sometimesAuth = to.matched.some(route => route.meta.sometimesAuth === true)

  try {
    if (auth.user.id === null || auth.user.id === undefined) {
      if (auth.attempted) {
        throw new Error('Authorization already attempted.')
      }

      await store.dispatch('auth/authorize')
    }

    const { user } = store.state.auth

    const redirectConditions = [
      // Redirect user if they have not accepted terms and conditions.
      () => {
        if (user.terms_accepted === false) {
          if (to.name !== 'onboarding.complete') {
            return { name: 'onboarding.complete' }
          }

          return true
        }
      },

      // Redirect provider users and medical professionals if haven't finished account setup.
      () => {
        if (!user.finished_account_setup && user.role.name === 'provider') {
          if (to.name !== 'register-provider-onboard') {
            return { name: 'register-provider-onboard', replace: true }
          }

          return true
        } else if (!user.finished_account_setup && user.role.name === 'user') {
          if (to.name !== 'register-user-onboard') {
            return { name: 'register-user-onboard', replace: true }
          }

          return true
        }
      },

      // Redirect to email verification if user hasn't verified their email.
      () => {
        if (user.email_verified === false) {
          if (to.name !== 'verification') {
            return { name: 'verification', replace: true }
          }

          return true
        }
      },

      // Allow navigating to subscription plan selection page if user has not selected a plan.
      () => {
        if (user.has_subscription) {
          return false
        }

        if (user.requires_enterprise && to.name !== 'register.subscribe' && to.name !== 'verification') {
          return { name: 'register.subscribe', replace: true }
        }

        if (to.name === 'register.subscribe' || to.name === 'verification') {
          return true
        }

        if (to.name !== 'verification' && isA('provider')) {
          return { name: 'verification', replace: true }
        }
      },

      // Redirect to 2FA setup if user has not opted in or out of 2FA.
      () => {
        if (user.two_factor_required === null) {
          if (to.name !== 'two-factor.setup') {
            return { name: 'two-factor.setup', replace: true }
          }

          return true
        }
      },

      // Redirect user to setup 2FA method if their enabled method does not match their preferred method.
      () => {
        if (user.two_factor_method_enabled !== user.two_factor_method_preferred) {
          if (to.name !== `two-factor.setup.${user.two_factor_method_preferred}`) {
            return { name: `two-factor.setup.${user.two_factor_method_preferred}`, replace: true }
          }

          return true
        }
      },

      // Redirect user to 2FA verification if 2FA is enabled but not verified.
      () => {
        if (user.two_factor_method_enabled && user.two_factor_verified === false) {
          if (to.name !== 'two-factor.verification') {
            return { name: 'two-factor.verification', replace: true }
          }

          return true
        }
      },

      // Redirect to intended path
      () => {
        if (app.sessionTimedOut.intended) {
          const intended = app.sessionTimedOut.intended
          store.dispatch('app/setSessionTimedOut', {
            timedOut: false,
            intended: null
          })
          return next({ path: intended, replace: true })
        }
      }
    ]

    // If attempting to logout allow it.
    if (to.name === 'logout') {
      return next()
    }

    // Run through redirect conditions and perform any redirects if needed.
    for (const condition of redirectConditions) {
      const redirect = condition()

      if (redirect) {
        return next(redirect)
      }
    }

    // If no redirects hit above and the route navigating to requires or somethings requires auth
    // then we'll continue to the next route.
    if (requiresAuth || sometimesAuth) {
      return next()
    }

    // Otherwise force back to dashboard as route is intended for guest users only.
    next({ name: 'dashboard', replace: true })
  } catch (error) {
    if (requiresAuth) {
      // store the guest users intended route for after they login
      if (to.name !== 'login' && to.name !== 'register') {
        store.dispatch('app/setSessionTimedOut', {
          timedOut: false,
          intended: to.path
        })
      }

      return next({ name: 'login', replace: true })
    }

    next()
  }
}
