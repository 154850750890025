import { make } from 'vuex-pathify'
import { http } from '@/utils'

const state = {
  accreditationValues: []
}

const actions = {
  ...make.actions(state),

  async getAccreditationValues ({ dispatch, rootGetters }, params = {}) {
    const response = await http.get(`${rootGetters['courses/uri'](':id')}/accreditation-values`, { params })

    dispatch('setAccreditationValues', response.data.data)
  },

  async updateAccreditationValues ({ dispatch, rootGetters }, accreditationValues) {
    await http.put(`${rootGetters['courses/uri'](':id')}/accreditation-values`, accreditationValues)
  }
}

const mutations = make.mutations(state)

export const storeModule = {
  namespaced: true,
  state,
  actions,
  mutations
}
